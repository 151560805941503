@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
   --color-black-100: hsl(206, 5%, 100%);
   --color-black-200: hsl(206, 5%, 90%);
   --color-black-300: hsl(206, 5%, 80%);
   --color-black-400: hsl(206, 5%, 65%);
   --color-black-500: hsl(206, 5%, 50%);

   --color-white-100: hsl(213, 20%, 10%);
   --color-white-200: hsl(213, 23%, 8%);
   --color-white-300: hsl(214, 21%, 6%);
   --color-white-400: hsl(210, 21%, 6%);
   --color-white-500: hsl(216, 22%, 4%);
   --color-white-600: hsl(220, 18%, 3%);
   --color-white-700: hsl(220, 27%, 2%);
   --color-white-800: hsl(180, 20%, 1%);

   --color-blue-100: hsl(214, 95%, 93%);
   --color-blue-200: hsl(213, 97%, 87%);
   --color-blue-300: hsl(212, 96%, 78%);
   --color-blue-400: hsl(213, 94%, 68%);
   --color-blue-500: hsl(217, 91%, 60%);
   --color-blue-600: hsl(221, 83%, 53%);
   --color-blue-700: hsl(224, 76%, 48%);
   --color-blue-800: hsl(226, 71%, 40%);
   --color-blue-900: hsl(224, 64%, 33%);

   --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.06);
   --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
   --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

*,
*::before,
*::after {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
   list-style: none;
   text-decoration: none;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-rendering: optimizeLegibility;
}

html {
   font-size: 100%;
   box-sizing: inherit;
   scroll-behavior: smooth;
   height: -webkit-fill-available;
}

body {
   font-family: "Rubik", sans-serif;
   font-size: clamp(1rem, 2vw, 1.125rem);
   font-weight: 400;
   line-height: 1.5;
   height: -webkit-fill-available;
   color: var(--color-white-100);
   background-color: white
}

main {
   overflow: hidden;
}

a,
button {
   cursor: pointer;
   border: none;
   outline: none;
   user-select: none;
   background: none;
   box-shadow: none;
   text-decoration: none;
}

img,
video {
   display: block;
   max-width: 100%;
   height: auto;
   object-fit: cover;
}

.section {
   margin: 0 auto;
   padding: 6rem 0 1rem;
}

.container {
   max-width: 80%;
   height: auto;
   margin: 0 auto;
   padding: 0 1.25rem;
}

.centered {
   text-align: center;
   vertical-align: middle;
   margin-bottom: 1rem;
}

.heading-xl {
   font-family: inherit;
   font-size: clamp(2.648rem, 6vw, 4.241rem);
   font-weight: 700;
   line-height: 1.15;
   letter-spacing: -1px;
   max-width: 8em;
}

.heading-lg {
   font-family: inherit;
   font-size: clamp(2.179rem, 5vw, 3.176rem);
   font-weight: 700;
   line-height: 1.15;
   letter-spacing: -1px;
}

.heading-md {
   font-family: inherit;
   font-size: clamp(1.794rem, 4vw, 2.379rem);
   font-weight: 700;
   line-height: 1.25;
   letter-spacing: -1px;
}

.heading-sm {
   font-family: inherit;
   font-size: clamp(1.476rem, 3vw, 1.782rem);
   font-weight: 600;
   line-height: 1.5;
}

.heading-xs {
   font-family: inherit;
   font-size: clamp(1.215rem, 2vw, 1.335rem);
   font-weight: 500;
   line-height: 1.5;
}

.paragraph {
   font-family: inherit;
   font-size: clamp(1rem, 2vw, 1.125rem);
   text-wrap: balance;
   color: var(--color-white-200);
}

.btn {
   display: inline-block;
   font-family: monteserrat, sans-serif;
   font-size: 1rem;
   font-weight: 500;
   line-height: 1.5;
   text-align: center;
   vertical-align: middle;
   white-space: nowrap;
   user-select: none;
   outline: none;
   border: none;
   border-radius: 0.25rem;
   text-transform: unset;
   transition: all 0.3s ease-in-out;
}

.btn:hover{
   background-color: #ffd700;
}

.btn-inline {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   column-gap: 0.5rem;
}

.btn-darken {
   padding: 0.75rem 2rem;
   color: var(--color-white-100);
   background-color: var(--color-black-200);
   box-shadow: var(--shadow-medium);
}

.btn-neutral {
   padding: 0.75rem 2rem;
   color: var(--color-black-500);
   background-color: var(--color-white-100);
   box-shadow: var(--shadow-medium);
}

.header {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 100;
   width: 100%;
   height: auto;
   margin: 0 auto;
   transition: all 0.35s ease;
   background-color: white;
   padding-top: 1em;
}

.header.on-scroll {
   background: var(--color-black-300);
   box-shadow: var(--shadow-medium);
}

.navbar {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   column-gap: 1.25rem;
   width: 100%;
   height: 4.25rem;
   margin: 0 auto;
}

.brand {
   font-family: inherit;
   font-size: 1.3rem;
   font-weight: 600;
   line-height: 1.5;
   letter-spacing: -1px;
   color: var(--color-white-100);
   text-transform: uppercase;
}

.menu {
   position: fixed;
   top: -100%;
   left: 0;
   width: 100%;
   height: auto;
   padding: 4rem 0 3rem;
   overflow: hidden;
   background-color: var(--color-black-300);
   box-shadow: var(--shadow-medium);
   transition: all 0.4s ease-in-out;
}

.menu.is-active {
   top: 0;
   width: 100%;
   height: auto;
}

.menu-inner {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   row-gap: 1.25rem;
}

.menu-link {
   font-family: 'futura-std-light', sans-serif;
   padding: 1em 0.75em;
   border: #ffd700 0.2em;
   border-radius: 1em;
   font-size: 1rem;
   font-weight: 500;
   line-height: 1.5;
   color: var(--color-white);
   text-transform: uppercase;
   transition: all 0.3s ease-in-out;
}

.menu-link:hover{
    background-color: #ffd700;
    border-radius: 1em;
}

.menu-block {
   display: none;
   font-family: inherit;
   font-size: 0.875rem;
   font-weight: 500;
   line-height: 1.25;
   user-select: none;
   white-space: nowrap;
   text-align: center;
   margin-left: auto;
   padding: 0.65rem 1.5rem;
   border-radius: 0;
   text-transform: capitalize;
   color: var(--color-white);
   background-color: #ffd700;
   box-shadow: var(--shadow-medium);
   transition: all 0.3s ease-in-out;
}

.menu-block:hover{
   background-color: #083f88;
   color: var(--color-black-100);
}

.bl-icon{
   transition: all 0.3s ease-in-out;
}

.bl-icon:hover{
   scale: 1.3;
}

@media only screen and (min-width: 48rem) {
   .menu {
      position: relative;
      top: 0;
      width: auto;
      height: auto;
      padding: 0rem;
      margin-left: auto;
      background: none;
      box-shadow: none;
   }

 

   .menu-inner {
      display: flex;
      flex-direction: row;
      column-gap: 2rem;
      margin: 0 auto;
   }

   .menu-link {
      text-transform: capitalize;
   }

   .menu-block {
      margin-left: 2rem;
      display: inline-block;
   }
}

.burger-container{
   display: flex;
   flex-direction: column;
   position: relative;
}

.burger-menu{
   display: flex;
   position: absolute;
}

.burger {
   display: block;
   cursor: pointer;
   user-select: none;
   order: -1;
   z-index: 10;
   width: 1.6rem;
   height: 1.15rem;
   border: none;
   outline: none;
   background: none;
   visibility: visible;
   transform: rotate(0deg);
   transition: 0.35s ease;
}

@media only screen and (min-width: 48rem) {
   .burger {
      display: none;
      visibility: hidden;
   }
}

.burger-line {
   position: absolute;
   display: block;
   left: 0;
   width: 100%;
   height: 2px;
   border: none;
   outline: none;
   opacity: 1;
   border-radius: 1rem;
   transform: rotate(0deg);
   background-color: var(--color-white-100);
   transition: 0.25s ease-in-out;
}

.burger-line:nth-child(1) {
   top: 0px;
}

.burger-line:nth-child(2) {
   top: 0.5rem;
   width: 70%;
}

.burger-line:nth-child(3) {
   top: 1rem;
}

.burger.is-active .burger-line:nth-child(1) {
   top: 0.5rem;
   transform: rotate(135deg);
}

.burger.is-active .burger-line:nth-child(2) {
   opacity: 0;
   visibility: hidden;
}

.burger.is-active .burger-line:nth-child(3) {
   top: 0.5rem;
   transform: rotate(-135deg);
}

.banner-column {
   position: relative;
   display: grid;
   align-items: center;
   row-gap: 3rem;
}

@media only screen and (min-width: 48rem) {
   .banner-column {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      justify-content: center;
   }
}

@media only screen and (min-width: 64rem) {
   .banner-column {
      grid-template-columns: 1fr max-content;
      column-gap: 4rem;
      margin-top: 3rem;
   }

   .brand{
      font-size: 1.6rem;
   }
}

.banner-image {
   display: block;
   max-width: 18rem;
   height: auto;
   margin-top: 2rem;
   object-fit: cover;
   justify-self: center;
}

@media only screen and (min-width: 48rem) {
   .banner-image {
      order: 1;
      max-width: 20rem;
      height: auto;
   }
}

@media only screen and (min-width: 64rem) {
   .banner-image {
      max-width: 25rem;
      height: auto;
      margin-right: 5rem;
   }
}

.banner-inner {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
  
}
.banner-inner > p {
   margin-top: 0.25em;
  line-height: 1.3rem;
}

.banner-inner > button {
   margin-top: 2em;
}

.banner-links {
   position: absolute;
   top: 30%;
   right: 1.5rem;
   display: grid;
   justify-items: center;
   row-gap: 0.5rem;
   opacity: 0;
   visibility: hidden;
}

@media only screen and (min-width: 64rem) {
   .banner-links {
      opacity: 1;
      visibility: visible;
   }
}

.banner-links > * {
   font-size: 1.25rem;
   line-height: 1.25;
   color: var(--color-white-100);
}

.banner-links::before {
   position: absolute;
   content: "";
   top: -3rem;
   width: 4rem;
   height: 1.5px;
   transform: rotate(90deg);
   background: var(--color-white-100);
}

.banner-links::after {
   position: absolute;
   content: "";
   bottom: -3rem;
   width: 4rem;
   height: 2px;
   transform: rotate(90deg);
   background: var(--color-white-100);
}


