.navigation-sub-container {
  display: flex;
  width: 100%;
  max-width: 1440px;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
}
.navigation-sub {
  max-width: 1440px;
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}

.nav-container-sub {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.item1 {
}

.nav-brand-sub {
  font-family: "lastica", sans-serif;
  letter-spacing: 0.2ch;
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
  grid-column-start: 2;
  grid-column-end: 5;
  color: black;
}

.nav-links-sub {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  grid-column-start: 8;
  grid-column-end: 12;
  margin: 0 auto;
}

.nav-links-sub li {
  font-family: "futura-std-light", sans-serif;
  font-size: 0.7rem;
  letter-spacing: 0.2ch;
  cursor: pointer;
  font-weight: bold;
}

.nav-links-sub li:hover {
  text-decoration: none;
}

.nav-links-sub a {
  text-decoration: none;
  color: black;
  transition: all 0.3s ease-in-out;
}

.nav-links-sub a:hover {
  text-decoration: underline;
}

.hamberger__menu__icon {
  display: none;
  background-color: transparent;
}

.hamberger__menu__icon button {
  border-width: 0px;
  background-color: transparent;
  color: black;
}

.hamberger__menu {
  display: flex;
  margin-top: 5em;
  right: 1em;
  background-color: white;
  position: absolute;
  z-index: 10;
  padding: 1.5em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: transform all 0.3s ease-in-out;
}

.hamberger__menu__hide {
  display: none;
}

.hamberger__menu ul {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  list-style: none;
  padding: 0;
  text-align: center;
  color: #707070;
}

.hamberger__menu li {
  font-family: "futura-std-heavy", sans-serif;
  font-size: 0.7rem;
  letter-spacing: 0.2ch;
}

.hamberger__menu button {
  border-width: 0px;
  background-color: white;
}

.hamberger__menu button:hover {
  background-color: tomato;
}

@media (max-width: 768px) {
  .hamberger__menu__icon {
    display: block;
    grid-column: 12/13;
  }

  .nav-links-sub {
    display: none;
  }
}
