.about__landing__container {
  max-width: 1440px;
  margin: 1em auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about__landing__container h2 {
  font-family: "futura-std-heavy", sans-serif;
  font-size: 2rem;
  margin: 1em 0.5em 0 0.5em;
  text-align: center;
}

.about__landing__container p {
  font-family: "futura-std-book", sans-serif;
  font-size: 0.85rem;
  margin: 1em 0.5em 0 0.5em;
  max-width: 720px;
  text-align: center;
  color: #707070;
  letter-spacing: 0.1ch;
  line-height: 1.5em;
}

.about__landing__container span{
  color: #083F88;
  font-weight: 900;
}

.about__landing__images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2em;
  margin: 2em 0 1em 0;
}

.about__landing__images img {
  display: block;
  width: 40%;
}

.about__landing__speciality {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15em, 20em));
  margin: 1em auto;
  max-width: 1440px;
  justify-content: center;
  align-items: center;
  column-gap: 1em;
}

.about__landing__speciality img {
  display: block;
  width: 30%;
  margin: 0 auto;
}

.about__landing__speciality h5 {
  text-align: center;
  font-family: "futura-std-book", sans-serif;
  letter-spacing: 0.1ch;
  font-size: 0.85rem;
  font-weight: 400;
  margin: 0 auto;
  text-transform: uppercase;
}

.about__landing__speciality p{
  font-family: 'futura-std-book', sans-serif;
  color: #707070;
  font-size: 0.7rem;
  margin: 1em 0 0 0 ;
  text-align: center;

}

.get__in__touch {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 4em auto;
}

.get__in__touch h3 {
  font-family: "futura-std-heavy", sans-serif;
  letter-spacing: 0.1ch;
  text-align: center;
  font-size: 2rem;
  margin: 1em 0 0.5em 0;
}

.get__in__touch p {
  font-family: "futura-std-book", sans-serif;
  margin: 1em auto;
  text-align: center;
  max-width: 768px;
  color: #707070;
  font-size: 0.85rem;

}

.get__in__touch input {
  max-width: 30em;
  width: auto;
  min-width: 20em;
  justify-self: center;
  align-self: center;
  margin: 1em auto;
  padding: 1em;
  border-width: 1px;
}

.get__in__touch input::placeholder {
  font-family: "futura-std-book", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.2ch;
  font-weight: 900;
}

.get__in__touch input:focus {
  outline: none;
}

.get__in__touch button {
  max-width: 15em;
  min-width: 10em;
  padding: 1em;
  margin: 0 auto;
  border-width: 0px;
  color: white;
  background-color: black;
  font-family: 'futura-std-book',sans-serif;
  letter-spacing: 0.1ch;
}

.get__in__touch button:hover{
    background-color: gold;
    color: black;
}

@media (max-width:768px){
    .about__landing__images{
        flex-direction: column;
    }

    .about__landing__images img{
        width: 80%;
    }

}
