.whats-app-icon-container {
  display: flex;
  position: fixed;
  background-color: #25d366;
  right: 0;
  top: 6em;
  z-index: 1;
  transition: background-color 0.3s ease;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
}

.whats-app-icon-container:hover {
  background-color: #083f88;
}

.whats-app-content {
  display: flex;
  flex-direction: row;
  padding: 1em 1em 1em 1.5em;
}

@media screen and (max-width:600px) {
    .whats-app-icon-container{
        margin-top: 5.6em;
    }
}

