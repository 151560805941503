/* Header - Section 1 */

.header__top__bar {
  padding: 0.6em 0em;
  margin: 0em auto;
  align-items: center;
  justify-content: center;
  background-color: #043677;
  width: 100%;
}

.header__top__bar h3 {
  font-family: "futura-std-book", sans-serif;
  font-size: 1rem;
  margin: 0;
  color: #ffd700;
}

/* Header - Section 2 - middle bar*/
.header__middle__bar {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.middle__brands {
  justify-content: center !important;
  gap: 1em;
  padding: 0.6em 0em;
}

.middle__brands p {
  font-family: "futura-std-light", sans-serif;
  font-size: 0.7rem;
  letter-spacing: 0.2ch;
  cursor: pointer;
  font-weight: bold;
  color: black;
}

.middle__brands p:hover {
  text-decoration: underline;
}

/* Car Popup */
.car__popup__container__none {
  display: none;
}

.car__popup_container {
  display: flex;
  position: absolute;
  flex-direction: row;
  width: 250%;
  margin: 2.4em auto;
  gap: 5em;
  padding: 2em;
  align-self: center;
  justify-self: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 10;
  background-color: white;
}

.car__popup__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 20em;
}

.car__popup__list h2 {
  font-family: "futura-std-medium", sans-serif;
  letter-spacing: 0.1ch;
  margin: 0em 0em 0.4em 0;
}

.car__popup__list ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 0 0.5em;
}

.car__popup__list li {
  font-family: "futura-std-book", sans-serif;
  font-size: 0.8rem;
  margin: 1em 0;
  font-weight: 600;
  color: #707070;
  cursor: pointer;
  text-align: left;
  letter-spacing: 0.2ch;
}

.car__popup__list li:hover {
  color: #000000;
}

.car__popup__details {
  max-width: 20em;
}

.car__popup__details p {
  font-family: "futura-std-book", sans-serif;
  font-size: 0.8rem;
  margin: 0.3em 0;
  font-weight: 400;
  color: #707070;
  cursor: pointer;
  justify-self: center;
  align-self: center;
}

.car__popup__image {
  display: flex;
  flex-direction: column;
}

.car__popup__image img {
  max-width: 15em;
}

.car__popup__image button {
  border-width: 0px;
  background-color: white;
  padding: 1em 1em 0.8em 1em;
  align-self: flex-end;
  width: 20%;
  margin-top: 0.2em;
}

.car__popup__image button:hover {
  background-color: tomato;
}
/* navigation */

.navigation {
  max-width: 1440px;
  align-self: center;
}

.nav-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.item1 {
}

.nav-brand {
  font-family: "lastica", sans-serif;
  letter-spacing: 0.2ch;
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
  grid-column-start: 2;
  grid-column-end: 5;
  color: #043677;
}

.nav-links {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  grid-column-start: 5;
  grid-column-end: 9;
  margin: 0 auto;
}

.nav-links li {
  font-family: "futura-std-light", sans-serif;
  font-size: 0.7rem;
  letter-spacing: 0.2ch;
  cursor: pointer;
  font-weight: bold;
}

.nav-links li:hover {
  text-decoration: none;
  
}

.nav-links a {
  text-decoration: none;
  color:#043677;
  transition: color 0.3s ease-in-out;
}

.nav-links a:hover {
  color: white;
}

.hamberger__menu__icon {
  display: none;
  background-color: transparent;
}

.hamberger__menu__icon button {
  border-width: 0px;
  background-color: transparent;
  color: white;
}

.hamberger__menu {
  display: flex;
  right: 1em;
  background-color: white;
  position: absolute;
  z-index: 10;
  padding: 1.5em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.hamberger__menu__hide {
  display: none;
}

.hamberger__menu ul {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  list-style: none;
  padding: 0;
  text-align: center;
  color: #707070;
}

.hamberger__menu li {
  font-family: "futura-std-heavy", sans-serif;
  font-size: 0.7rem;
  letter-spacing: 0.2ch;
}

.hamberger__menu button {
  border-width: 0px;
  background-color: white;
}

.hamberger__menu button:hover {
  background-color: tomato;
}

/* Footer */
.footer-container {
  background-color: #043677;
  text-align: center;
  padding-top: 2em;
}

.footer-main {
  display: flex;
  max-width: 1440px;
  margin: auto;
  justify-content: space-around;
}

.footer-column {
  display: flex;
  flex-direction: column;
}

.footer-column h3 {
  font-family: "futura-std-book", sans-serif;
  color: gold;
  letter-spacing: 0.5ch;
  font-size: 1rem;
}

.footer-column p {
  font-family: "futura-std-light", sans-serif;
  color: #f2f2f2;
  margin: 0 0 0.5em 0;
  cursor: pointer;
  letter-spacing: 0.2ch;
  font-size: 0.9rem;
    

}

.remove__und{
  text-decoration: none;
}

.footer-column p:hover {
  text-decoration: underline;
}

.logo-footer {
  margin-top: 1em;
  border-radius: 0.2em;
  width: 100%;
  margin-top: 3em;
}

.footer-social {
  gap: 1em;
  justify-content: center;
  align-items: center;
  margin: 1em 0;
}

.footer__leagal a {
  text-decoration: none;
}
.footer__leagal p {
  text-decoration: none;
  color: white;
  font-family: "futura-std-book", "sans-serif";
  margin: 0 0 0.5em 0;
}

.footer-lastline p {
  font-family: "futura-std-book", sans-serif;
  padding: 1em 0 0 0 ;
  margin: 0;
  color: #f2f2f2;
}

.footer-lastline h2 {
  font-family: "futura-std-light", sans-serif;
  font-size: 0.85rem;
  margin: 0;
  padding: 0 0 1em 0;
  color: white;
  letter-spacing: 0.1ch;
}

.footer__lastline__link {
  text-decoration: underline;
  color: inherit;

}

.social-media-icon-container {
  background-color: #202020;
  width: 3em;
  height: 3em;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.social-media-icon-container:hover {
  background-color: gold;
}

.social-media-icon {
  color: #ffffff;
  width: 1.3em;
  height: 1.3em;
}

@media (max-width: 1024px) {
  .header__top__bar h3 {
    font-size: 0.6rem;
  }

  .middle__brands p {
    font-size: 0.6rem;
  }
}

@media (max-width: 768px) {
  .car__popup_container {
    flex-direction: column;
    width: 150%;
    gap: 1em;
  }

  .car__popup__list {
    align-self: center;
  }

  .car__popup__details {
    align-self: center;
  }

  .car__popup__image {
    align-self: center;
  }

  .nav-links {
    display: none;
  }

  .hamberger__menu__icon {
    display: block;
    grid-column: 12/13;
  }
}

@media (max-width: 480px) {
  .footer-column h3 {
    font-size: 0.8rem;
  }

  .footer-column p {
    font-size: 0.7rem;
  }

  .car__popup_container {
    width: 100%;
  }

  .car__popup__image button {
    background-color: tomato;
  }
}

@media (max-width: 1440px) {
  .footer-container {
    padding: 0em 1em;
  }
}
