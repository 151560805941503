.contact__container {
  display: flex;
  flex-direction: column;
}

.contact__container h1 {
  font-family: "futura-std-heavy", sans-serif;
  font-size: 2rem;
  text-transform: uppercase;
  text-align: center;
  margin: 1em 0 0.5em 0;
}

.contact__container p {
  font-family: "futura-std-book", sans-serif;
  font-size: 0.85rem;
  max-width: calc(60%);
  text-align: left;
  color: #707070;
  margin: 0 auto;
}

.contact__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  row-gap: 1em;
  column-gap: 1em;
  max-width: 1440px;
  margin: 2em auto;
}

.contact__form input {
  padding: 1em;
  border: 1px solid #707070;
  width: 20em;
  font-family: "futura-std-book", sans-serif;
}

.contact__container input::placeholder {
  font-family: "futura-std-book", sans-serif;
  top: 0;
}

.contact__form__message {
  font-family: "futura-std-book", sans-serif;
}

.contact__container input:focus {
  outline: 0px;
}

.contact__form__message {
  grid-row: 2/5;
  grid-column: 2/3;
}

.contact__form__button {
  grid-column: 1/3;
  margin: 0 auto;
  min-width: 20em;
  border-width: 0;
  font-family: "futura-std-book", sans-serif;
  color: white;
  background-color: #000000;
  letter-spacing: 0.1ch;
}

.contact__form__button:hover {
  background-color: gold;
  color: #000000;
}

.contact__details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3em;
}

.contact__detail__container {
  display: flex;
  flex-direction: column;
  border: 2px solid #707070;

  justify-content: flex-start;
  align-items: center;
  margin: 1em 0;
  padding: 1em;
  width: 25%;
}

.contact__detail__container h2 {
  font-family: "futura-std-heavy";
  letter-spacing: 0.1ch;
  text-transform: uppercase;
  margin: 1em 0 0 0;
}

.contact__detail__container h3 {
  font-family: "futura-std-medium", sans-serif;
  color: black;
  letter-spacing: 0.1ch;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .contact__form {
    display: grid;
    grid-template-rows: repeat(9, 1fr);
    grid-template-columns: 100%;
  }

  .contact__form__message {
    grid-column: 1/2;
    grid-row: 6/9;
  }

  .contact__form__button {
    grid-column: 1/2;
    grid-row: 9/10;
  }

  .contact__details{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0em;
  }

  .contact__detail__container{
    width: 75%;
  }
}
