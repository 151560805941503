.burger-menu-container{
    background-color: white;
    display: flex;
    flex-direction: column;
    margin-top: 5em;
    padding: 0.75em;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.bm-item{
    padding: 0.25em 1em;
    text-align: center;
    font-family: 'Futura-std-light', sans-serif;
    cursor: pointer;
    border-radius: 0.25em;
    transition: all 0.3s ease-in-out;
}

.bm-item:hover{
    background-color: lightgray;
}