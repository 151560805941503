@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap");

@font-face {
  font-family: dalmais;
  src: url(./assets/fonts/Dalmais/Dalmais.otf);
}

@font-face {
  font-family: lastica;
  src: url(./assets/fonts/Lastica.ttf);
}

@font-face {
  font-family: cantata-one;
  src: url(./assets/fonts/cantata-one/CantataOne-Regular.ttf);
}

@font-face {
  font-family: futura-std-book;
  src: url(./assets/fonts/Futura/FuturaStdBook.otf);
}

@font-face {
  font-family: futura-std-condensed;
  src: url(./assets/fonts/Futura/FuturaStdCondensed.otf);
}

@font-face {
  font-family: futura-std-condensed-bold;
  src: url(./assets/fonts/Futura/FuturaStdCondensedBold.otf);
}

@font-face {
  font-family: futura-std-medium;
  src: url(./assets/fonts/Futura/FuturaStdHeavy.otf);
}

@font-face {
  font-family: futura-std-heavy;
  src: url(./assets/fonts/Futura/FuturaStdHeavy.otf);
}

@font-face{
  font-family:futura-std-light;
  src: url(./assets/fonts/Futura/FuturaStdLight.otf);
}


  
.App {
  text-align: center;
  margin: 0;
 overflow: hidden;
}

/* Texts */

.main__heading {
  font-family: futura-std-heavy, "sans-serif";
  font-weight: bolder;
  font-size: 6rem;
  font-weight: 900;
  margin: 0;
  letter-spacing: 0ch;
}

.main__heading__2{
  font-family: dalmais, "sans-serif";
  font-weight: 900;
  font-size:4rem;
  letter-spacing: ch;
  margin: 0;
}

.subheading__1 {
  font-family: cantata-one, sans-serif;
  font-weight: 900;
  font-size: 3rem;
  letter-spacing: 0.1ch;
  line-height: 2ch;
  margin: 0;
}

.subheading__2 {
  font-family: cantata-one, sans-serif;
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 0.05ch;
  line-height: 2ch;
  margin: 0;
}

.subheading__3 {
  font-family: cantata-one, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 0.05ch;
  line-height: 2ch;
  margin: 0;
}

.paragraph {
  font-family: cantata-one, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0ch;
  line-height: 2ch;
  margin: 0;
}

.tag__1{
  font-family: cantata-one, sans-serif;
  font-weight: 700;
  font-size: 0.9rem;
  letter-spacing: 0.1ch;
  margin: 0;
}

.tag__2{
  font-family:cantata-one, sans-serif;
  font-weight: 700;
  font-size: 0.6rem;
  margin: 0;
}


/* Fonts Futura Sans */
.title-f{

}

.main__heading-f{

}

.subheading__1-f{

}

.subheading__2-f{

}

.subheading__3-f{

}

.subheading__4-f{

}

.paragraph-f{

}

.tag__1-f{

}

.tag__2-f{

}



/* Layouts */
.container{
  max-width: 1440px;

}

.column{
  display: flex;
  flex-direction: column;

}

.row{
  display: flex;
  flex-direction: row;

}

/* colors */
.wh{
  color: white;
}

.pk{
  color:#FFD700;
}

.sc{
  color: #083F88;
}

.ac{
  color: #FFD700;
}

.bg__1{
  background-color: #083F88;
  color: white;
}

.bg__2{
  background-color: #FFD700;
  color: black;
}

.bg__3{
  background-color: #FFD700;
  color: white;
}

.bg__4{
  background-color: #FFD700;
  color: black;
}

/* Artworks */
.line__1{
  width: 90%;
  height: 2px;
  background-color: 	rgba(8, 63, 136, 0.5);
}