/* main container */
.main-container{
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
  
}

/* hero and header components */
.hero-header-container {
  /* background image */
  background-image: url(../assets/home/hero/bck-img.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* new hero  */
.hero-slider-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%;
}

.slider-main-container {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.hero-navigation-button {
  display: flex;
  position: absolute;
  z-index: 1;
  align-self: center;
}

.hero__large__img {
  height: fit-content;
}

.hr-prev-btn {
  left: 0;
  margin-left: 1em;
}

.hr-nxt-btn {
  right: 0;
  margin-right: 1em;
}

.h__n__mc {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
}

.h__n__mc img {
  width: 100%;
  display: block;
  margin: 0.8em auto;
}

.hero__med__img {
  display: none;
}

.rent__from__us {
  position: absolute;
  left: 5em;
  top: 20em;
  display: flex;
  flex-direction: column;
}

/* Launch Animation end */
.rent__from__us__buttons {
  display: flex;
  flex-direction: row;
  gap: 1em;
  margin-top: -50px;
}

.rent__from__us h2 {
  margin: 0;
}

.rent__from__us h4 {
  margin: 0 0.5em 0 0;
  text-emphasis: filled;
  align-self: flex-end;
  font-family: "futura-std-light", sans-serif;
  letter-spacing: 0.2ch;
}

.rent__from__us__btn1 {
  font-family: "futura-std-light", sans-serif;
  letter-spacing: 0.2ch;
  font-weight: bolder;
  padding: 0.8em 0em;
  width: 50%;
  align-self: flex-end;
  margin: 1em 0em;
  border: 3px solid #083f88;
  background: transparent;
  color: #083f88;
}

.rent__from__us__btn1:hover {
  background-color: #083f88;
  color: white;
}

.rent__from__us__btn2 {
  font-family: "futura-std-light", sans-serif;
  letter-spacing: 0.2ch;
  font-weight: bolder;
  padding: 1em 0em;
  width: 50%;
  align-self: flex-end;
  margin: 1em 0em;
  border-width: 0px;
}

.rent__from__us__btn2:hover {
  background-color: #ffd700;
}

.h__n__mc h2 {
  grid-column: 3/11;
  grid-row: 4/5;
  display: flex;
  align-self: center;
  justify-self: center;
  color: #083f88;
  font-family: "futura-std-heavy", sans-serif;
  font-weight: 900;
  letter-spacing: 0.2ch;
  font-size: 3rem;
}

/* Why Choose - Section 3 */
.why__choose__container {
  max-width: 1440px;
  margin: 2em auto;
  text-align: center;
}

.why__choose__cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10em, 20em));
  row-gap: 2em;
  column-gap: 2em;
  margin: 0 auto;
  justify-content: center;
}

.why__choose__card img {
  display: block;
  width: 50%;
  margin: 0 auto;
}

.why__choose__container h2 {
  margin: 0;
  font-family: "futura-std-medium", "sans-serif";
  font-size: 2rem;
  letter-spacing: 0.1ch;
}

.why__choose__card h3 {
  margin: 0em 0em 0.4em 0em;
  font-size: 1rem;
  font-family: "futura-std-book", sans-serif;
  letter-spacing: 0.3ch;
}

.why__choose__card p {
  margin: 0;
  text-align: center;
  font-family: "futura-std-light", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.2ch;
}

/* Our Services */
.our__services__container {
  margin: 5em auto;
  text-align: center;
}

.our__services__container h4 {
  margin: 0;
  font-family: "futura-std-medium", "sans-serif";
  font-size: 2rem;
  letter-spacing: 0.1ch;
}

.our__services__container h2 {
  margin: 0;
  font-family: "futura-std-medium", "sans-serif";
  font-size: 1.2rem;
  letter-spacing: 0.1ch;
}

.our__services__cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 20rem));
  justify-content: center;
  margin: 2em auto;
  gap: 3em;
}

.our__services__card {
  flex: 1;
  padding: 1em;
  border-radius: 0em;
  align-items: flex-start;
}

.our__services__card:hover {
  border: 1px solid black;
}

.our__services__card img {
  width: 100%;
  border-radius: 0em;
  display: block;
}

.our__services__card h3 {
  margin: 3em 0em 0.4em 0em;
  font-size: 1.5rem;
  font-family: "futura-std-medium", sans-serif;
  letter-spacing: 0.4ch;
}

.our__services__card__service {
  margin: 0 0 0.5em 0.3em;
  gap: 0.6em;
}

.our__services__card p {
  margin: 0;
  text-align: left;
  font-family: "futura-std-light", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.2ch;
}

.our__services__card button {
  justify-self: center;
  align-self: flex-start;
  margin-top: 1em;
  padding: 0.5em 1em;
  border-width: 0px;
  font-family: "futura-std-light", sans-serif;
  letter-spacing: 0.2em;
}

.our__services__cards button:hover {
  background-color: #ffd700;
}

/* Cars - Section 3*/
.explore__cars__header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}


.explore__car__change {
  margin: 1em auto;
  display: flex;
  justify-content: center;
  gap: 2em;
}

.explore__car__change button {
  border: none;
  background-color: white;
  font-family: "futura-std-medium", sans-serif;
  font-size: 1rem;
  padding: 0.5em 0.5em 0em 0.5em;
  cursor: pointer;
}

.selected__car__change {
  border-bottom: 3px solid #ffd700 !important;
}

.not__selected__car__change {
  border-bottom: 3px solid rgba(112, 112, 112, 0.2) !important;
}

.explore__car__change__none {
  display: none;
}

.explore__cars__header > h3 {
  font-family: "futura-std-light", sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.4ch;
  font-size: 2rem;
  margin: 0 0 0.5em 0;
  text-align: center;
}

.wrapper__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 25rem));
  justify-content: center;
  gap: 1em 1em;
}

.cars__main__container {
  margin: 0 auto;
}

.cars__row {
  gap: 1em;
  margin: 1em auto;
}

.individual__car {
  flex: 1;
  margin: 0em 0em 1em 1em;
  justify-content: flex-start;
  align-items: center;
}

.adv-service-container button {
  margin-top: 2em;
  cursor: pointer;
}

.individual__car img {
  width: 100%;
}

.individual__car h4 {
  text-align: left;
  margin-top: 1em;
  align-self: flex-start;
  font-family: "futura-std-medium", sans-serif;
  letter-spacing: 0.1ch;
  font-size: 1.5rem;
  margin: 0.3em 0 0 0;
}

.individual__car__price {
  display: flex;
  flex-direction: row;
}

.individual__car h5 {
  text-align: left;
  margin-top: 1em;
  align-self: flex-start;
  font-family: "futura-std-medium", sans-serif;
  letter-spacing: 0.1ch;
  font-size: 1.5rem;
  margin: 0.3em 0 0 0;
  color: #083f88;
}

.individual__car p {
  text-align: left;
  align-self: flex-start;
  justify-self: flex-end;
  margin-bottom: 0em;
  font-family: "futura-std-light", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.2ch;
  margin-top: 0.7em;
}

.individual__car button {
  font-family: "futura-std-light", sans-serif;
  letter-spacing: 0.2ch;
  font-weight: bolder;
  padding: 0.8em 0em;
  width: 50%;
  align-self: flex-end;
  color: white;
  background-color: black;
  font-family: "futura-std-book", sans-serif;
  letter-spacing: 0.1ch;
  cursor: pointer;
}

.individual__car button:hover {
  background-color: gold;
  color: black;
}

.individual__car__price {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
}

.i__car__brand p {
  align-self: flex-start;
}

.cars__icon {
  width: 4em;
  color: #083f88;
}

/* Booking Form */
.booking-form-main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  display: absolute;
}
.booking-form-main-container > form {
  width: 50%;
}

.booking-form {
  background-color: white;
  width: fit-content;
  z-index: 20;
  margin: 4em auto 0em auto !important;
  margin-top: 20em;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.booking-form > h4 {
  font-family: futura-std-light, sans-serif;
  letter-spacing: 0.2ch;
  font-size: 1.5rem;
  text-transform: uppercase;
  max-width: 20em;
  text-align: center;
}

.booking-form__overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: anchor-center;
  margin: 6em auto 0em auto;
  top: 0;
  background-color: white;
  z-index: 2;
  border: #083f88 0.1em solid;
  padding: 1em;
}

.booking-form-input-fields-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  width: 100%;
  padding: 0 2em;
}

.booking__form__input__fields {
  padding: 0 2em;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.5em;
}

.booking-form-input-fields-emirates{
  display: flex;
  border: 1px solid black;
  padding: 0.75em 0.5em;
  margin-bottom: 1em;
}

.booking__form__input__fields > label {
  font-family: futura-std-light, sans-serif;
  font-size: 0.9rem;
  letter-spacing: 0.2ch;
  width: 100%;
}

.booking__form__input__fields > input {
  padding: 0.5em;
  width: 30em;
  border-width: 1px;
  border-color: black;
  font-family: futura-std-light, sans-serif;
}

.booking__form__input__fields > input::placeholder {
  font-family: futura-std-light;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.2ch;
}

.booking__form__input__fields > input:focus {
  outline: none;
}
/* Booking form buttons */
.booking__form__button__container {
  margin-top: 2em;
  display: flex;
  justify-content: center;
  gap: 1em;
}
.booking__form__button__container > button {
  padding: 1em 2.5em;
  border: none;
  font-family: futura-std-light, sans-serif;
  letter-spacing: 0ch;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.button__close:hover {
  background-color: rgb(250, 99, 99);
}

.button__book:hover {
  background-color: gold;
}

.toast-message-success {
  background-color: #ffd700;
  color: black;
}

/* Booking form */
.select-car {
  color: black;
}

.booking-form-menu-item {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* booking form second column */
/* bookign form car specifications */
/* frm-21 */
.booking-form-data-container {
  background-color: rgba(217, 217, 217, 0.2);
  width: 50%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
}

.bfdc-specifications-container {
  margin-top: 4em;
  margin-left: 3em;
}

.bfdc-specifications-container > p {
  font-family: "futura-std-light", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.2ch;
  font-size: 1.2rem;
  text-align: center;
}

.bfdc-specifications-item-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 3em;
}

.bfdc-specifications-item {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.bfdc-specifications-item-content {
  display: flex;
  flex-direction: column;
}

.bfdc-specifications-item-content > h6 {
  margin: 0;
  font-family: "futura-std-book", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.1ch;
}

.bfdc-specifications-item-content > p {
  margin: 0;
  font-family: "futura-std-light", sans-serif;
  text-transform: uppercase;
}

/* booking form youtube video */
/* frm-22 */
.bfdc-video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  margin-left: 3em;
}

.bfdc-video-container > p {
  font-family: "futura-std-light", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.bfdc-video-player {
  padding-bottom: 56.25%;
  width: 560px;
  height: 315px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: #083f88;
}

.bfdc-video-player > iframe {
  width: 100%;
  height: 100%;
  border-width:1px ;
  padding: 0.2em;
  border-radius: 2em;
  border-color: black;
 
}

/*.h__booking__form {
  grid-column: 3/11;
  grid-row: 5/7;
  background-color: #083f88;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.h__inputs {
  display: flex;
  flex-direction: row;
  position: relative;
  grid-column: 5/6;
  justify-content: space-around;
  margin-top: 3em;
}

.h__inputs label {
  text-align: left;
  margin-bottom: 1em;
  color: #ffd700;
  font-family: "futura-std-medium", sans-serif;
  letter-spacing: 0.2ch;
}

.h__inputs input {
  height: 2.5em;
  border-width: 0;
  background-color: #f2f2f2;
  color: #083f88;
  font-family: "futura-std-light", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.2ch;
  font-weight: bold;
  padding: 0em 1em;
}

.h__inputs input:focus {
  outline: none;
}

.h__date__inputs {
  display: flex;
  flex-direction: row;
  gap: 3em;
}

.h__city input {
  max-width: 40em;
}

.h__pickup input {
  width: 10em;
}

.h__drop input {
  width: 10em;
}

.h__submit__button {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 6/7;
  grid-column: 3/11;
  margin-bottom: 1em;
}

.h__submit__button button {
  padding: 1em 2em;
  border-width: 0em;
  font-family: "futura-std-medium", sans-serif;
  letter-spacing: 0.2ch;
  align-self: flex-end;
}

.h__submit__button button:hover {
  background-color: #083f88;
  color: #ffd700;
} */

/* Media quaries for standered values
##Breakbpoints
1440px - max laptop/pc
1200px - laptop/pc
1024px - laptop/pc
768px - tablet
600px - large mobiles
480px - mobile
*/

@media (max-width: 1200px) {
  .bfdc-video-player {
    width: 400px;
    height: 224px;
  }

  .booking-form-data-container{
    align-items: flex-start;
  }

  .bfdc-video-container{
    margin-left: 1em;
  }

  .bfdc-specifications-container{
    margin-left: 1em;
  }
}

@media (max-width: 1024px) {
  .h__n__mc {
    grid-template-rows: repeat(6, 5em);
  }

  .h__n__mc h2 {
    grid-row: 3/4;
  }

  .h__booking__form {
    grid-row: 4/7;
    padding: 1em;
  }

  .h__inputs {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1em;
    gap: 1em;
  }

  .h__inputs label {
    font-size: 0.8rem;
  }

  .h__date__inputs {
    flex-direction: row;
    gap: 3em;
  }

  .h__submit__button {
    align-self: flex-end;
    margin-bottom: 0.2em;
  }

  .hero__med__img {
    display: none;
  }

  /* Images */
  .hero__large__img {
    display: none;
  }

  .hero__med__2__img {
    display: block;
  }

  .hero__small__img {
    display: none;
  }

  .rent__from__us {
    top: 12em;
  }

  .rent__from__us h2 {
    font-size: 2.5rem;
  }

  /* booking form responsive css design */
  .booking-form-main-container{
    flex-direction: column;
    overflow-y: auto;
    align-items: center;
  }

  .booking-form-main-container > form{
  width: 100%;  
  }

  .booking-form-data-container{
    width: 100%;
    align-items: center;
    margin-top: 2em;
  }

  .bfdc-video-player{
    width: 560px;
    height: 350px;
  }

  .booking-form > h4{
    max-width: 30em;
    margin: 0 2em;
  }
  
  .booking-form-input-fields-container{
    align-items: center;
    padding: 0;
  }

  .booking__form__input__fields{
    padding: 0;
  }

  .booking__form__input__fields > input{
    width: 30em;
  }
}

@media (min-width: 1023px) {
  .hero__med__2__img {
    display: none;
  }

  .hero__small__img {
    display: none;
  }
}

@media (max-width: 768px) {
  .hero__med__image {
    display: none;
  }

  .hero__med__2__img {
    display: block;
  }

  .hero__large__img {
    display: none;
  }

  .hero__small__img {
    display: none;
  }
}

@media(max-width: 600px){
  .bfdc-video-player{
    width: 420px;
    height: 235.2px;
  }

  .bfdc-specifications-item-container{
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2,1fr);
    gap: 2em;
  }
}

@media (max-width: 480px) {
  .hero__small__img {
    display: block;
  }

  .hero__med__2__img {
    display: none;
  }

  .rent__from__us {
    margin-top: 15em;
  }

  .rent__from__us h2 {
    color: #ffd700;
    margin-bottom: 0.3em;
  }

  .rent__from__us__btn1 {
    border-color: #ffd700;
    color: #ffd700;
  }

  .rent__from__us h2 {
    text-shadow: 2px 2px 5px black;
  }

  .booking__form {
    margin: auto 0;
  }
  /* new booking form responsive styles */
  .booking-form > h4{
    margin:0 1em;
  }

  .booking__form__input__fields > input{
    width: 20em;
  }

  .bfdc-video-player{
    width: 300px;
    height: 168px;
  }
}

/* Non standered Media Quaries */
@media (max-width: 1440px) {
  .rent__from__us {
    left: 2em;
    top: 12em;
  }
}

@media (max-width: 1150px) {
  .rent__from__us {
    top: 12em;
  }
}

@media (max-width: 864px) {
  .rent__from__us {
    top: 10em;
  }
}

@media (max-width: 768px) {
  .rent__from__us {
    top: 8em;
  }
}

@media (max-width: 562px) {
  .rent__from__us h4 {
    color: white;
  }
}

@media (max-width: 418px) {
  .rent__from__us {
    margin-top: 10em;
  }
}
